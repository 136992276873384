import { useFlashSale } from '@/containers/FlashSaleContainer'
import { useEffect, useState } from 'react'

interface CountdownType {
  endTime: number
  type: string
  productId: string
  className?: string
}
const Countdown = ({ endTime, type, productId, className }: CountdownType) => {
  const { dispatch } = useFlashSale()
  const savedTime = new Date(endTime)
  const countDownDate = new Date(savedTime).getTime()
  const [countDown, setCountDown] = useState(
    countDownDate - new Date().getTime()
  )
  const [days, setDays] = useState(0)
  const [hours, setHours] = useState(0)
  const [minutes, setMinutes] = useState(0)
  const [seconds, setSeconds] = useState(0)

  useEffect(() => {
    setDays(Math.floor(countDown / (1000 * 60 * 60 * 24)))
    setHours(
      Math.floor(
        (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60) + days * 24
      )
    )
    setMinutes(Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60)))
    setSeconds(Math.floor((countDown % (1000 * 60)) / 1000))
  }, [countDown])
  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(countDownDate - new Date().getTime())
      if (countDownDate - new Date().getTime() < 0) {
        setDays(0)
        setHours(0)
        setMinutes(0)
        setSeconds(0)
        setCountDown(0)
        dispatch({
          type: 'DELETE_PRODUCT',
          payload: {
            productId,
          },
        })
        clearInterval(interval)
      }
    }, 1000)

    return () => clearInterval(interval)
  }, [countDownDate])

  return (
    <div
      className={`countDown z-[19] flex w-full items-center justify-center text-xl font-extrabold leading-[18px] text-white ${
        type == 'product-detail'
          ? 'relative mb-1 h-[44px] justify-center gap-x-[4px]'
          : 'absolute bottom-[2px] left-[50%] h-[38px] translate-x-[-50%] gap-[4px] px-2 '
      } ${className}`}
    >
      {hours < 10 ? (
        <div
          className={`${
            type == 'product-detail'
              ? 'h-[44px] w-[44px] rounded bg-[#010103]/80 text-xl'
              : 'h-full w-[38px] rounded bg-[#010103]/80'
          } formCountTime flex items-center justify-center gap-x-[2px] `}
        >
          <span className={'block'}>0</span>
          <span className={'block'}>{hours}</span>
        </div>
      ) : hours >= 24 ? (
        <div
          className={`${
            type == 'product-detail'
              ? 'h-[44px] w-[44px] rounded bg-[#010103]/80 text-xl'
              : 'h-full w-[38px] rounded bg-[#010103]/80'
          } formCountTime flex items-center justify-center gap-x-[2px] `}
        >
          {hours % 24 > 10 ? (
            <>
              <span className={'block'}>{`${hours % 24}`?.charAt(0)}</span>
              <span className={'block'}>{`${hours % 24}`?.charAt(1)}</span>
            </>
          ) : (
            <>
              <span className={'block'}>0</span>
              <span className={'block'}>{`${hours % 24}`?.charAt(0)}</span>
            </>
          )}
        </div>
      ) : (
        <div
          className={`${
            type == 'product-detail'
              ? 'h-[44px] w-[44px] rounded bg-[#010103]/80 text-xl'
              : 'h-full w-[38px] rounded bg-[#010103]/80'
          } formCountTime flex items-center justify-center gap-x-[2px] `}
        >
          <span className={'block'}>{`${hours}`?.charAt(0)}</span>
          <span className={'block'}>{`${hours}`?.charAt(1)}</span>
        </div>
      )}
      <span
        className={`${
          type == 'product-detail' ? '' : ''
        } delimiter text-[#010103]`}
      >
        :
      </span>
      {minutes < 10 ? (
        <div
          className={`${
            type == 'product-detail'
              ? 'h-[44px] w-[44px] rounded bg-[#010103]/80 text-xl'
              : 'h-full w-[38px] rounded bg-[#010103]/80'
          } formCountTime flex items-center justify-center gap-x-[2px] `}
        >
          <span className={'block'}>0</span>
          <span className={'block'}>{minutes}</span>
        </div>
      ) : (
        <div
          className={`${
            type == 'product-detail'
              ? 'h-[44px] w-[44px] rounded bg-[#010103]/80 text-xl'
              : 'h-full w-[38px] rounded bg-[#010103]/80'
          } formCountTime flex items-center justify-center gap-x-[2px] `}
        >
          <span className={'block'}>{`${minutes}`?.charAt(0)}</span>
          <span className={'block'}>{`${minutes}`?.charAt(1)}</span>
        </div>
      )}
      <span
        className={`${
          type == 'product-detail' ? '' : ''
        } delimiter text-[#010103]`}
      >
        :
      </span>
      {seconds < 10 ? (
        <div
          className={`${
            type == 'product-detail'
              ? 'h-[44px] w-[44px] rounded bg-[#010103]/80 text-xl'
              : 'h-full w-[38px] rounded bg-[#010103]/80'
          } formCountTime flex items-center justify-center gap-x-[2px] `}
        >
          <span className={'block'}>0</span>
          <span className={'block'}>{seconds}</span>
        </div>
      ) : (
        <div
          className={`${
            type == 'product-detail'
              ? 'h-[44px] w-[44px] rounded bg-[#010103]/80 text-xl'
              : 'h-full w-[38px] rounded bg-[#010103]/80'
          } formCountTime flex items-center justify-center gap-x-[2px] `}
        >
          <span className={'block'}>{`${seconds}`?.charAt(0)}</span>
          <span className={'block'}>{`${seconds}`?.charAt(1)}</span>
        </div>
      )}
    </div>
  )
}

export default Countdown
