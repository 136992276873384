import { FlashSaleType } from '@/constants'
import { useMemo } from 'react'
export interface ProgressBarProps {
  flashSale: {
    quantity: number
    soldQuantity: number
    endTime: number
  }
  type?: string
  fsType?: FlashSaleType
}

const ProgressBar = ({ flashSale, type, fsType }: ProgressBarProps) => {
  const leftItems = useMemo(() => {
    if (fsType === FlashSaleType.PROMOTION) {
      return 99
    }
    if (flashSale) {
      return (
        ((Number(flashSale.quantity) - Number(flashSale.soldQuantity)) /
          Number(flashSale.quantity)) *
        100
      )
    }
    return 0
  }, [flashSale])

  const renderProgressSeller = useMemo(() => {
    if (leftItems <= 0 && flashSale.soldQuantity > 0) {
      return (
        <div className='totalCount'>
          <p
            className={`${
              type == 'product-detail'
                ? 'left-[50%] w-max translate-x-[-50%] text-center text-sm font-bold leading-[25px] text-dark-900'
                : 'left-[36%]'
            } available absolute top-[50%] translate-y-[-50%] text-sm font-bold text-dark-900`}
          >
            Đã bán hết
          </p>
        </div>
      )
    } else if (leftItems < 90 && leftItems > 0) {
      return (
        <div className='totalCount'>
          <p
            className={`${
              type == 'product-detail'
                ? 'left-[50%] w-max translate-x-[-50%] text-center text-sm font-bold leading-[25px] text-dark-900'
                : 'left-[36%]'
            } available absolute top-[50%] translate-y-[-50%] text-sm font-bold text-dark-900`}
          >{` Còn ${flashSale.quantity - flashSale.soldQuantity}/${
            flashSale.quantity
          } suất`}</p>
        </div>
      )
    } else if (leftItems >= 90) {
      return (
        <div className='totalCount'>
          <p
            className={`${
              type == 'product-detail'
                ? 'left-[50%] w-max translate-x-[-50%] text-center text-sm font-bold leading-[25px] text-dark-900'
                : 'left-[36%]'
            } available absolute top-[50%] translate-y-[-50%] text-sm font-bold text-dark-900`}
          >{`Vừa mở bán`}</p>
        </div>
      )
    }
  }, [flashSale])
  return (
    <div className={`${type == 'product-detail' ? '' : 'pb-0'} h-full`}>
      <div
        className={`${
          leftItems <= 0 && flashSale.soldQuantity > 0
            ? 'bg-gradient-to-r from-grey-200 to-grey-600'
            : ' bg-grey-700 dark:bg-grey-700'
        } relative h-full w-full overflow-hidden rounded-full`}
      >
        <div
          className={`${
            type == 'product-detail'
              ? 'justify-center bg-flashSale'
              : 'bg-flashSale text-sm font-medium leading-none text-dark-900'
          } flex h-[30px] items-center rounded-full text-center`}
          style={{
            width: leftItems >= 90 ? '100%' : leftItems + '%',
          }}
        >
          {renderProgressSeller}
        </div>
      </div>
    </div>
  )
}

export default ProgressBar
