import { Languages, ProductCardType } from '@/constants'
import { useAuth, useStore } from '@/containers'
import { useTranslations } from 'next-intl'

import Link from 'next/link'

interface PriceType {
  type?: string
  className?: string
  link?: string
  priceAtWarehouse?: boolean
  hasGift?: boolean
  hasDeal?: boolean
  originPrice: number
  finalPrice: number
  stockStatus?: string
}

export const Price = ({
  type,
  originPrice,
  finalPrice,
  hasGift,
  hasDeal,
  priceAtWarehouse,
  className = '',
  link = '',
  stockStatus = '',
}: PriceType) => {
  const { token } = useAuth()
  const { selectedLang } = useStore()
  const t = useTranslations('ProductDetail')
  const currency = (lang: string) => {
    switch (lang) {
      case 'vi':
        return 'VND'
      case 'en':
        return 'VND'
      default:
        return 'VND'
    }
  }

  const percent = Math.ceil(100 - (finalPrice / originPrice) * 100)
  return (
    <div className={`${className} price-wrapper w-full`}>
      {type === ProductCardType.PRODUCT_DETAIL && stockStatus !== 'OOS' && (
        <span className='mb-0.5 block text-lg font-bold text-dark-400'>
          {hasDeal
            ? 'Giá DEAL độc quyền'
            : priceAtWarehouse
            ? t('warehousePrice')
            : hasGift
            ? 'Giá online rẻ hơn'
            : t('priceWithShip')}
          :
        </span>
      )}
      {stockStatus === 'OOS' && (
        <span className='mb-0.5 block text-lg font-bold text-dark-400'>
          {t('priceWithShip')}
        </span>
      )}
      <div className='sale-price flex items-center justify-between'>
        {token ? (
          <p
            className={`${
              type === 'product-detail'
                ? 'text-3xl font-black text-red-500'
                : 'text-2xl font-extrabold text-red-500'
            } leading-10`}
          >
            {finalPrice &&
              finalPrice
                .toLocaleString(Languages.VN, {
                  style: 'currency',
                  currency: currency(selectedLang as string),
                })
                .replace(/\s₫/g, 'đ')}
          </p>
        ) : (
          <Link href={link} className='flex items-center gap-x-1 text-mainBody'>
            <p
              className={`${
                type === 'product-detail'
                  ? 'text-3xl font-black text-red-500'
                  : 'text-2xl font-extrabold text-red-500'
              }  leading-10 `}
            >
              {finalPrice &&
                finalPrice
                  .toLocaleString(Languages.VN, {
                    style: 'currency',
                    currency: currency(selectedLang as string),
                  })
                  .replace(/\s₫/g, 'đ')}
            </p>
          </Link>
        )}
      </div>
      <div className='originPrice flex-start flex items-center gap-x-1'>
        <p
          className={`${
            type === 'product-detail'
              ? 'text-lg text-zinc-600'
              : 'text-base text-zinc-500'
          } leading-normal line-through`}
        >
          {originPrice &&
            originPrice
              .toLocaleString(Languages.VN, {
                style: 'currency',
                currency: currency(selectedLang as string),
              })
              .replace(/\s₫/g, 'đ')}
        </p>
        {percent > 0 && (
          <span className='discount flex w-max items-baseline gap-[4px] rounded bg-red-500 text-sm leading-5 text-stone-50'>
            <span className='flex items-center px-[4px] text-white'>
              <span className='value'>{'-' + percent}</span>
              <span className='unit'>%</span>
            </span>
          </span>
        )}
      </div>
    </div>
  )
}

export default Price
