import { useStore } from '@/containers'

export interface PreOrderCardProps {
  price: number
  type: string
}
const PreOrderCard = ({ price, type }: PreOrderCardProps) => {
  const { selectedLang } = useStore()
  const currency = (lang: string) => {
    switch (lang) {
      case 'vi':
        return 'VND'
      case 'en':
        return 'USD'
      default:
        return 'VND'
    }
  }
  return (
    <div
      className={`${
        type == 'product-detail' ? ' mt-2' : ''
      } m-auto flex h-full items-center justify-center rounded-full bg-preOrder px-4 text-center`}
    >
      <span className='mr-1 text-sm font-bold text-white'>Đặt trước chỉ:</span>
      <span className='text-base font-extrabold text-white'>
        {price
          ?.toLocaleString(selectedLang as string, {
            style: 'currency',
            currency: currency(selectedLang as string),
          })
          .replace(/\s₫/g, 'đ')}
      </span>
    </div>
  )
}

export default PreOrderCard
